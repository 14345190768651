import logoSTU from '../../src/components/assests/images/logoSTU.jpeg';
import logoaiotlabvn from '../../src/components/assests/images/wordlogo.png';
import itp from '../../src/components/assests/images/logoITP.png';
import yss from '../../src/components/assests/images/yss.png'
import ai4ia from '../../src/components/assests/images/logoAI4IA.jpg'
export const Organization = () => {
  return (
    <>
      <div id="venue" className="lg:px-24 lg:my-20 px-4 mb-10 py-6 bg-white text-blue_super_dark flex flex-col items-center">
        <p className="font-bold text-3xl text-center mb-4">ORGANIZERS & SPONSORS</p>
        <div className="flex flex-col md:flex-row items-center justify-center">
          <div className="mb-6 lg:mb-0 flex justify-center">
            <img src={logoSTU} alt="STU Logo" className="h-[100px] lg:h-[140px] mx-4"/>
          </div>
          <div className="mb-6 lg:mb-0 flex justify-center">
            <img src={logoaiotlabvn} alt="AIoT Lab VN Logo" className="h-[100px] lg:h-[100px] mx-4"/>
          </div>
          <div className="mb-6 lg:mb-0 flex justify-center">
            <img src={itp} alt="ITP Logo" className="h-[100px] lg:h-[130px] mx-4"/>
          </div>
          <div className="mb-6 lg:mb-0 flex justify-center">
            <img src={yss} alt="YSS Logo" className="h-[100px] lg:h-[110px] mx-4"/>
          </div>
          <div className="mb-6 lg:mb-0 flex justify-center">
            <img src={ai4ia} alt="AI4IA Logo" className="h-[100px] lg:h-[130px] mx-4"/>
          </div>
        </div>
      </div>
    </>
  );
};
