import React from 'react';

const ConferencePoster = () => {
  return (
    <div className="flex pt-10 flex-col justify-center items-center h-screen bg-gray-100">
      <embed
        src="./ATAC2024-Conference.pdf"
        type="application/pdf"
        className="w-screen h-screen"
        title="ATAC Conference Poster"
      />
    </div>
  );
};

export default ConferencePoster;