import { Footer } from "../components/footer";
import ManuscriptReviewProcess from "../components/manuscript";
import Transition from "../utils/Transition";

export const AuthorGuidelines = () =>{
    return (
        <Transition>
        <div className="mt-[90px]">
            <ManuscriptReviewProcess/>
            <Footer/>
        </div>
        </Transition>
    );
}