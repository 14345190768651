export const About = () => {
  return (
    <>
      <div className="flex justify-center item-center flex-col pb-0 lg:pb-4 mb-4 lg:my-6  lg:h-[18rem] lg:px-16 px-6">
        <p className="text-3xl font-bold mb-4 lg:mx-10 sm:mx-2 tracking-wide">
          <span className="underline underline-offset-8 decoration-4 decoration-[#E30022]">
            Abo
          </span>
          ut the conference
        </p>
        <p className=" text-md lg:text-lg font-[400] lg:mx-10 sm:mx-2">
          Welcome to{" "}
          <strong>
            ATAC 2024 - The 2024 Conference on Applications of Technology,
            Automation, and Civil Engineering.
          </strong>{" "}
          This conference is designed specifically for Undergraduate, Graduate,
          and PhD Students, Young Scientists offering a unique platform for
          aspiring engineers and technologists to share their innovations and
          research findings. Scheduled for <strong>November 23, 2024,</strong>{" "}
          ATAC 2024 aims to foster collaboration, knowledge exchange, and skill
          development in the fields of Information Technology, Machines,
          Automation, and Civil Engineering. Join us as we explore the future of
          these disciplines and contribute to shaping the world of tomorrow.
        </p>

        <p className="mt-4 text-md lg:text-lg font-[400] lg:mx-10 sm:mx-2">
          Accepted papers will be published in a conference proceeding with
          ISBN. In addition, top papers from ATAC 2024 will be extended for
          publication in{" "}
          <a
            className="font-bold hover:text-blue_super_dark"
            href="https://www.isods.org/publications/index.php/jdsai/about"
            target="_blank"
            rel="noopener noreferrer"
          >
            The Journal of Data Science and Artificial Intelligence (JDSAI),
            ISSN 2831-4794 
          </a>
          . {""}This peer-reviewed journal focuses on Data Science, Deep Learning, and
          Machine Learning, providing a platform for global research
          dissemination. More details can be found{" "}
          <a
            className="font-bold hover:text-blue_super_dark"
            href="https://www.isods.org/publications/index.php/jdsai/about"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>
          .
        </p>
      </div>
    </>
  );
};
