import Transition from "../utils/Transition";

const DeclareSoon = () => {
    return (
      <Transition>
      <div className="flex justify-center items-center h-[100vh] bg-gray-100">
        <div className="text-center">
          <h1 className="text-4xl font-bold text-gray-800 mb-4">Coming Soon!</h1>
          <p className="text-gray-600 text-lg">We're working hard to get this page ready for you. Stay tuned!</p>
          <div className="mt-6">
            <svg
              className="animate-spin h-10 w-10 text-blue mx-auto"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
              ></path>
            </svg>
          </div>
          <p className="text-grey mt-4">Please check back later.</p>
        </div>
      </div>
      </Transition>
    );
  }
  
  export default DeclareSoon;
  