import { Footer } from "../components/footer";
import Transition from "../utils/Transition";

const Contact = () => {
    return (
        <Transition>
        <div className="flex flex-col min-h-screen">
            {/* Main Content */}
            <div className="flex-grow my-36 px-4 lg:px-36">
                <div className="bg-white w-full max-w-lg">
                    <p className="text-3xl font-bold mb-4 tracking-wide leading-normal">
                        <span className="underline underline-offset-8 decoration-4 decoration-[#E30022]">Con</span>tact Details
                    </p>
                    <p className="text-gray-700">
                        <strong>Conference Chair:</strong> The 2024 Conference on Applications of Technology, Automation and Civil Engineering (ATAC 2024)
                    </p>
                    <p className="text-gray-700 mt-2">
                        <strong>Location:</strong> Saigon Technology University (STU), 180 Cao Lo Str; Ward 4, Dist 8, HCMC
                    </p>
                    <p className="text-gray-700 mt-2">
                        <strong>Email:</strong> <a href="mailto:info@aiotlab.vn" className="text-blue_super_dark hover:underline">info@aiotlab.vn</a>
                    </p>
                </div>
            </div>
            
            {/* Footer */}
            <Footer />
        </div>
        </Transition>
    );
}

export default Contact;
