import { FaFacebook, FaLinkedin, FaGlobe } from "react-icons/fa";

export const Footer = () => {
  return (
    <footer className="px-4 divide-y bg-blue_super_dark text-white border-grey">
      <div className="py-4 text-sm text-center dark:text-gray-600 text-white">
        ©2024 AIoT Lab VN, All Right Reserved.
        <br />
        Developed by{" "}
        <a href="https://aiotlab.vn/" className="hover:underline" target="_blank" rel="noopener noreferrer">
          AIoT Lab VN
        </a>
        <br />
        <div className="flex justify-center space-x-3 text-white">
          <a
            href="https://www.facebook.com/aiotlab.vn"
            title="Facebook"
            className="flex items-center p-1"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebook className="w-5 h-5" />
          </a>
          <a
            href="https://www.linkedin.com/company/aiot-lab-vn/"
            title="LinkedIn"
            className="flex items-center p-1"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaLinkedin className="w-5 h-5" />
          </a>
          <a
            href="https://aiotlab.vn/"
            title="Website"
            className="flex items-center p-1"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaGlobe className="w-5 h-5" />
          </a>
        </div>
      </div>
    </footer>
  );
};
