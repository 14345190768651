import React, { useState, useEffect } from "react";
import stulogo from "./assests/images/logoSTU.jpeg";
import aiotlabvnlogo from "./assests/images/wordlogo.png";
import { Link, useLocation } from "react-router-dom";

export const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const location = useLocation();

  // Close the navbar when navigating to another page
  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      setIsOpen(false); // Close the dropdown when the window is resized
    };

    handleResize(); // Initial check on component mount

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <nav className="absolute top-0 left-0 w-full z-50 bg-white text-blue_super_dark shadow-md">
      <div className="flex items-center justify-between lg:justify-between flex-wrap p-2 pb-0 mx-0 lg:mr-4">
        <Link to="/">
          <div className="pl-5 flex items-center justify-center flex-shrink-0 mr-2 md:mr-14">
            <p className="font-bold text-2xl lg:text-4xl tracking-wide mr-2">
              ATAC2024
            </p>
          </div>
        </Link>
        <div className="block lg:hidden">
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="flex items-center px-3 py-2 rounded text-black hover:text-grey"
          >
            <svg
              className={`fill-current h-10 w-10 ${isOpen ? "hidden" : "block"}`}
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
            <svg
              className={`fill-current h-10 w-10 ${isOpen ? "block" : "hidden"}`}
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z" />
            </svg>
          </button>
        </div>
        <div
          className={`w-full lg:flex lg:items-center lg:w-auto ${
            isOpen ? "flex flex-col items-center" : "hidden"
          }`}
        >
          <div className="text-md font-medium lg:flex-wrap flex flex-col lg:flex-row items-center">
            <Link
              to="/"
              className="text-2xl font-bold block mt-4 lg:inline-block lg:mt-0 text-blue_super_dark mr-4 group"
            >
              Home
              <div className="bg-blue h-[2px] w-0 group-hover:w-full transition-all duration-500"></div>
            </Link>

            <Link
              to="/author-guide"
              className="text-xl block mt-4 lg:inline-block lg:mt-0 text-blue_super_dark mr-4 group"
            >
              Guideline
              <div className="bg-blue h-[2px] w-0 group-hover:w-full transition-all duration-500"></div>
            </Link>

            <Link
              to="/committees"
              className="text-xl block mt-4 lg:inline-block lg:mt-0 text-blue_super_dark mr-4 group"
            >
              Committees
              <div className="bg-blue h-[2px] w-0 group-hover:w-full transition-all duration-500"></div>
            </Link>

            <Link
              to="/register"
              className="text-xl block mt-4 lg:inline-block lg:mt-0 text-blue_super_dark mr-4 group"
            >
              Registration
              <div className="bg-blue h-[2px] w-0 group-hover:w-full transition-all duration-500"></div>
            </Link>

            <Link
              to="/contact"
              className="text-xl block mt-4 lg:inline-block lg:mt-0 text-blue_super_dark group"
            >
              Contact
              <div className="bg-blue h-[2px] w-0 group-hover:w-full transition-all duration-500"></div>
            </Link>
          </div>
          <div className="flex justify-center lg:justify-end"></div>
          <img src={stulogo} className=" h-[94px] mr-4" alt="Logo" />
          <img src={aiotlabvnlogo} className="h-[64px] mr-4" alt="Logo" />
        </div>
      </div>
    </nav>
  );
};
