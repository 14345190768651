import React, { useState } from "react";
import data from "../components/assests/committee.json";
import logoSTU from '../components/assests/images/logoSTU.jpeg';
import logoaiotlabvn from '../components/assests/images/wordlogo.png';
import itp from '../components/assests/images/logoITP.png';
import yss from '../components/assests/images/yss.png';
import ai4ia from '../components/assests/images/logoAI4IA.jpg';
import { Footer } from "../components/footer";
import Transition from "../utils/Transition";

const Committees = () => {
  const committee = data.committee_members;

  // Extract unique responsibilities and move "Organizers & Sponsors" to the top
  const responsibilities = [
    "Organizers & Sponsors",
    ...new Set(committee.map(member => member.Responsibility)),
  ];

  // State to manage active responsibility
  const [activeResponsibility, setActiveResponsibility] = useState(responsibilities[0]);

  return (
    <Transition>
    <div className="flex flex-col min-h-screen">
      {/* Main Content */}
      <div className="flex-grow my-36 px-4 lg:px-36">
        {/* Display all responsibilities in a vertical list */}
        <div className="lg:hidden mb-4">
          {responsibilities.map((responsibility, index) => (
            <div key={index} className="mb-4">
              <button
                className="w-full p-2 bg-gray-100 border rounded"
                onClick={() => setActiveResponsibility(responsibility)}
              >
                {responsibility}
              </button>
              {activeResponsibility === responsibility && (
                <div className="mt-2 border rounded shadow-lg bg-white p-4">
                  <p className="text-3xl font-bold mb-4 tracking-wide leading-normal">
                    {responsibility}
                  </p>
                  {responsibility === "Organizers & Sponsors" ? (
                    <div className="flex flex-wrap justify-center items-center">
                      <div className="flex justify-center mb-6 lg:mb-0">
                        <img src={logoSTU} alt="STU Logo" className="h-[100px] lg:h-[100px] mx-4" />
                      </div>
                      <div className="flex justify-center mb-6 lg:mb-0">
                        <img src={logoaiotlabvn} alt="AIoT Lab VN Logo" className="h-[100px] lg:h-[70px] mx-4" />
                      </div>
                      <div className="flex justify-center mb-6 lg:mb-0">
                        <img src={itp} alt="ITP Logo" className="h-[100px] lg:h-[100px] mx-4" />
                      </div>
                      <div className="flex justify-center mb-6 lg:mb-0">
                        <img src={yss} alt="YSS Logo" className="h-[100px] lg:h-[90px] mx-4" />
                      </div>
                      <div className="flex justify-center mb-6 lg:mb-0">
                        <img src={ai4ia} alt="AI4IA Logo" className="h-[100px] lg:h-[110px] mx-4" />
                      </div>
                    </div>
                  ) : (
                    <div className="flex flex-col items-start">
                      {committee
                        .filter(member => member.Responsibility === responsibility)
                        .map(({ Name_of_Faculty, unit }, index) => {
                          const [name] = Name_of_Faculty.split('(').map(item => item.trim());
                          return (
                            <div key={index} className="p-2 my-2">
                              <span className="font-bold">{name}</span>, <span>{unit}</span>
                            </div>
                          );
                        })}
                    </div>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>

        <div className="hidden lg:flex">
          {/* Sidebar for desktop view */}
          <div className="w-1/4 bg-gray-100 p-4">
            <ul>
              {responsibilities.map((responsibility, index) => (
                <li
                  key={index}
                  className={`p-2 cursor-pointer ${activeResponsibility === responsibility ? 'bg-gray-300' : ''}`}
                  onClick={() => setActiveResponsibility(responsibility)}
                >
                  {responsibility}
                </li>
              ))}
            </ul>
          </div>

          {/* Content Area */}
          <div className="w-3/4 p-4">
            <p className="text-3xl font-bold mb-4 tracking-wide leading-normal">
              {activeResponsibility}
            </p>

            {activeResponsibility === "Organizers & Sponsors" ? (
              <div className="flex flex-wrap justify-center items-center">
                <div className="flex justify-center mb-6 lg:mb-0">
                  <img src={logoSTU} alt="STU Logo" className="h-[100px] lg:h-[100px] mx-4" />
                </div>
                <div className="flex justify-center mb-6 lg:mb-0">
                  <img src={logoaiotlabvn} alt="AIoT Lab VN Logo" className="h-[100px] lg:h-[70px] mx-4" />
                </div>
                <div className="flex justify-center mb-6 lg:mb-0">
                  <img src={itp} alt="ITP Logo" className="h-[100px] lg:h-[100px] mx-4" />
                </div>
                <div className="flex justify-center mb-6 lg:mb-0">
                  <img src={yss} alt="YSS Logo" className="h-[100px] lg:h-[90px] mx-4" />
                </div>
                <div className="flex justify-center mb-6 lg:mb-0">
                  <img src={ai4ia} alt="AI4IA Logo" className="h-[100px] lg:h-[110px] mx-4" />
                </div>
              </div>
            ) : (
              <div className="flex flex-col items-start">
                {committee
                  .filter(member => member.Responsibility === activeResponsibility)
                  .map(({ Name_of_Faculty, unit }, index) => {
                    const [name] = Name_of_Faculty.split('(').map(item => item.trim());
                    return (
                      <div key={index} className="p-2 my-2">
                        <span className="font-bold">{name}</span>, <span>{unit}</span>
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Footer */}
      <Footer />
    </div>
    </Transition>
  );
};

export default Committees;
