import { Link } from "react-router-dom";
import img1 from "./assests/images/banner.JPG"
import newImg from "./new.gif"
export const Hero = () => {
  return (
    <div class="relative bg-gradient-to-r from-blue to-blue_super_dark h-screen text-white overflow-hidden">
      <div class="absolute inset-0">
        <img src={img1} alt="Background" class="object-cover object-center w-full h-full" />
        <div class="absolute inset-0 bg-black opacity-[0.45]"></div>
      </div>

      <div class="relative z-10 flex flex-col justify-center items-center h-full text-center m-2 lg:m-0">
        <h1 class="lg:text-5xl text-3xl font-semibold tracking-wide lg:font-[800] leading-tight mb-4">The 2024 Conference on 
          <br/>
          Applications of Technology, Automation 
          <br/>
          and Civil Engineering </h1>
        <h5 className="lg:text-3xl my-2 font-semibold text-xl">ATAC 2024</h5>
        {/* <h3 className="lg:text-6xl text-3xl font-bold lg:font-extrabold tracking-wide ">ATAC 2024</h3> */}
        <p class="text-xl font-semibold text-gray-300 mt-3">Saigon Technology University and AIoT Lab VN</p>
        <p class="text-xl font-semibold text-gray-300 ">Date:&nbsp;&nbsp;23 November,&nbsp;&nbsp;2024</p>
        <p class="text-xl font-semibold text-gray-300  mb-6">Ho Chi Minh City, Vietnam</p>
        <Link to="/author-guide" class="text-white bg-blue_dark transition-transform transform-gpu hover:-translate-y-2 hover:shadow-lg py-2 px-4 rounded-full text-lg font-semibold inline-flex items-center">
          <img className="h-4 mr-2" src={newImg} alt="Submit Icon"></img>
          <span>Submit your Paper</span>
        </Link>
      </div>
    </div>

  );
}