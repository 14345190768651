import tracks from "./tracks.json";

export const Guidelines = () => {
  return (
    <div className="bg-blue text-white py-2">
      <h1 className="text-white text-3xl font-bold flex justify-center items-center my-2">
        Tracks
      </h1>
      <ul className="mt-0 mx-auto text-left font-medium">
        {tracks.tracks.map((track) => (
          <li key={track.title}>
            <p className="py-1 w-full flex items-center font-[500] text-white hover:text-white_blue hover:bg-blue_super_dark">
              <span className="ml-5 mr-2.5 w-1 h-4 bg-white rounded-r-md"></span>
              {track.title}
            </p>
          </li>
        ))}
      </ul>
    </div>
  );
};
