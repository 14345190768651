import { Footer } from "../components/footer";
import RegisterDetails from "../components/registerDetails";
import Transition from "../utils/Transition";

const Registration = () => {
  return (
    <>
      <Transition>
        <div className="mt-[90px]">
          <RegisterDetails />
          <Footer />
        </div>
      </Transition>
    </>
  );
};

export default Registration;
